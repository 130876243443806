import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'

import Wrapper from '../UI/Wrapper'
import { ContentContainer, ImpactSection, StatItem, StatValue } from './styles'
import { booleanFilter } from '../../utils/helpers'

interface ImpactModuleProps {
  content: {
    headline: string | null
    stats:
      | readonly ({
          title: string
          value: string | null
        } | null)[]
      | null
    theme?: 'Dark' | 'Light'
  }
}

const ImpactModule = (props: ImpactModuleProps) => {
  const { headline, stats, theme = 'Dark' } = props.content
  const data = useStaticQuery<GatsbyTypes.backgroundImagesQuery>(graphql`
    query backgroundImages {
      contentstackResourcesWebsite {
        impact_module_asset_dark {
          description
          imgixImage {
            gatsbyImageData(imgixParams: { q: 60 })
          }
        }
        impact_module_asset_light {
          description
          imgixImage {
            gatsbyImageData(imgixParams: { q: 60 })
          }
        }
      }
    }
  `)

  // better typing for gatsbyImageData coming in gatsby-plugin-typegen v3
  const image = getImage(
    (theme === 'Dark'
      ? data.contentstackResourcesWebsite?.impact_module_asset_dark?.imgixImage
      : data.contentstackResourcesWebsite?.impact_module_asset_light
          ?.imgixImage) as any as ImageDataLike,
  )
  const altText =
    theme === 'Dark'
      ? data?.contentstackResourcesWebsite?.impact_module_asset_dark
          ?.description
      : data?.contentstackResourcesWebsite?.impact_module_asset_light
          ?.description

  return (
    <ImpactSection>
      {image ? <GatsbyImage alt={altText || ''} image={image} /> : null}
      <ContentContainer theme={theme}>
        <Wrapper>
          <Typography
            className="subhead"
            color="inherit"
            component="h2"
            variant="h2"
          >
            {headline}
          </Typography>
          <Grid className="statGrid" component="ul" container>
            {stats?.filter(booleanFilter).map((stat, index) => (
              <StatItem key={`stat${index}`}>
                <Typography
                  color="inherit"
                  component="p"
                  style={{ textTransform: 'uppercase' }}
                  variant="caption"
                >
                  <StatValue>{stat.value}</StatValue>
                  <span className="statCaption">{stat.title}</span>
                </Typography>
              </StatItem>
            ))}
          </Grid>
        </Wrapper>
      </ContentContainer>
    </ImpactSection>
  )
}

export default ImpactModule
