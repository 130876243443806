import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import Wrapper from '../UI/Wrapper'
import { KeyAreaItem, KeyAreasSection } from './styles'

const KeyAreas = (props: any) => {
  const { headline } = props?.content
  const keyAreas = props?.content?.key_areas?.length
    ? props?.content?.key_areas
    : props?.content?.topic?.[0]?.key_areas

  return (
    <KeyAreasSection>
      <Wrapper>
        <Typography className="headline" component="h2" variant="h2">
          {headline}
        </Typography>
        <Grid
          className="listContainer"
          component="ul"
          container
          justifyContent="center"
        >
          {keyAreas?.length > 0 &&
            keyAreas.map((area: any) => (
              <KeyAreaItem component="li" key={area.id}>
                <Typography className="subhead" component="h3" variant="h3">
                  {area.title}
                </Typography>
                <Typography color="textPrimary" component="p" variant="body1">
                  {area.short_description}
                </Typography>
              </KeyAreaItem>
            ))}
        </Grid>
      </Wrapper>
    </KeyAreasSection>
  )
}

export default KeyAreas
