import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

import LinkCTA from '../UI/LinkCTA'
import {
  AccessibilityGradient,
  AccessibilityOverlay,
  ContentWrapper,
  GradientOverlay,
  HalfWidth,
  HeroWrapper,
} from './styles'

interface HeroProps {
  content?: {
    feature_image: any
    feature_image_mobile?: any
    hero_video_resource:
      | readonly ({
          download: {
            id: string
            url: string | null
          } | null
        } | null)[]
      | null
    video: {
      url: string | null
      id: string
    } | null
    video_needs_accessibility_overlay: boolean | null
    cta_button?: readonly any[] | null
    text_align?: 'Left' | 'Right' | string | null
    text_color?: 'Dark' | 'Light' | string | null
    text_size?: 'Regular' | 'Large' | string | null
    subheading: string | null
    headline: string | null
    topic: readonly any[] | null
  } | null
}

const StyledLink = styled(LinkCTA)`
  a {
    border-radius: 7px;
    min-width: 50%;
    font-weight: 500;
    font-size: 18px;
    padding: 12px 24px;
  }
`

const Hero = (props: HeroProps) => {
  const { content } = props
  const textColor = content?.text_color || 'Light'
  const textSize = content?.text_size || 'Regular'
  const cta = content?.cta_button?.[0]

  const desktopImage = content?.feature_image
    ? getImage(content?.feature_image?.imgixImage)
    : getImage(content?.topic?.[0]?.feature_image?.imgixImage)

  const mobileImage = content?.feature_image_mobile
    ? getImage(content?.feature_image_mobile?.imgixImage)
    : getImage(content?.topic?.[0]?.feature_image_mobile?.imgixImage)

  const heroVideo =
    content?.video?.url ??
    content?.hero_video_resource?.[0]?.download?.url ??
    null

  const images =
    desktopImage && mobileImage
      ? withArtDirection(desktopImage, [
          {
            media: '(max-width: 580px)',
            image: mobileImage,
          },
        ])
      : undefined

  let wrapperClass = !desktopImage ? 'noImage' : cta ? 'largeVariant' : ''
  if (heroVideo) {
    wrapperClass = `${wrapperClass} video`
  }

  return (
    <HeroWrapper className={wrapperClass}>
      {heroVideo ? (
        <>
          <video autoPlay controls={false} loop muted playsInline>
            <source src={heroVideo} type="video/mp4" />
          </video>
          {content?.video_needs_accessibility_overlay ? (
            <AccessibilityOverlay />
          ) : null}
          <AccessibilityGradient />
        </>
      ) : null}

      {desktopImage && !heroVideo ? (
        <GatsbyImage
          alt={
            content?.feature_image?.description ||
            content?.topic?.[0]?.feature_image?.description ||
            ''
          }
          image={images || desktopImage}
          loading="eager"
        />
      ) : null}
      <GradientOverlay className={`overlay${textColor}`} />
      <ContentWrapper>
        <HalfWidth align={content?.text_align || 'Left'}>
          <Typography
            className={`headline headline${textColor} ${
              !desktopImage ? 'noImage' : ''
            } headline${textSize}`}
            component="h1"
            variant="h1"
          >
            {content?.headline || content?.topic?.[0]?.headline}
          </Typography>
          <Typography
            className={`subhead subhead${textColor} ${
              !desktopImage ? 'noImage' : ''
            } subhead${textSize}`}
            component="p"
            style={{ fontSize: 18, lineHeight: 1.5 }}
            variant="body1"
          >
            {content?.subheading || content?.topic?.[0]?.subheading}
          </Typography>
          {cta ? (
            <div style={{ marginTop: '40px' }}>
              <StyledLink link={cta.link?.[0]} variant="focus">
                {cta.text}
              </StyledLink>
            </div>
          ) : null}
        </HalfWidth>
      </ContentWrapper>
    </HeroWrapper>
  )
}

export default Hero
