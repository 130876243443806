import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const RelatedResourcesSection = styled.section`
  padding: 94px 0 72px;

  .cardContainer {
    margin-top: 56px;
    gap: 2.4rem;

    > span {
      flex-shrink: 1;
      flex-basis: 31%;
      min-width: 260px;
    }
  }

  .ctaContainer {
    margin-top: 48px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .headline {
      font-size: 2.8rem;
    }

    .cardContainer {
      margin-top: 32px;
      justify-content: center;

      > a {
        flex-shrink: 0;
        width: 100%;
        max-width: 360px;
        margin-bottom: 16px;
      }
    }

    .ctaContainer {
      margin-top: 32px;
    }
  }
`
