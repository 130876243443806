import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'

import { rspTheme } from '../../styles/rspTheme'

export const KeyAreasSection = styled(Box)({
  backgroundColor: rspTheme.palette.secondary.background,
  color: rspTheme.palette.secondary.dark,
  padding: '88px 0 80px',
  textAlign: 'center',

  '& .headline': {
    color: 'inherit',
  },

  '& .subhead': {
    color: 'inherit',
    marginBottom: '16px',
    maxWidth: '18ch',
  },

  '& .listContainer': {
    margin: '32px 0 0',
    padding: '0',
    listStyle: 'none',
    flexWrap: 'nowrap',
  },

  '@media(max-width:768px)': {
    padding: '48px 0 32px',
    textAlign: 'left',
    '& .headline': {
      fontSize: rspTheme.typography.h3.fontSize,
      letterSpacing: rspTheme.typography.h3.letterSpacing,
      lineHeight: rspTheme.typography.h3.lineHeight,
    },
    '& .subhead': {
      fontSize: rspTheme.typography.h4.fontSize,
      letterSpacing: rspTheme.typography.h4.letterSpacing,
      lineHeight: rspTheme.typography.h4.lineHeight,
    },
    '& .listContainer': {
      flexWrap: 'wrap',
    },
  },
})

export const KeyAreaItem = styled(Box)({
  minWidth: '200px',
  margin: '0 16px 16px',
  flexBasis: '25%',
  flexGrow: 1,
  textAlign: 'left',
  '& p': {
    maxWidth: '32ch',
  },
  '@media(max-width:768px)': {
    margin: '16px 0',
    flexBasis: '100%',
    '& p': {
      maxWidth: '60ch',
    },
  },
})
