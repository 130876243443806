import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'

import FeatureCardVertical from '../UI/FeatureCardVertical'
import LinkCTA from '../UI/LinkCTA'
import Wrapper from '../UI/Wrapper'
import { RelatedResourcesSection } from './styles'

interface RelatedResourcesProps {
  content: {
    headline?: string
    rows?: number
    cta_button?:
      | readonly ({
          text: string | null
          link:
            | readonly ({
                internal_overview_page: readonly { url: string }[] | null
              } | null)[]
            | null
        } | null)[]
      | null
    promoted_resources?: readonly any[]
    related_resources?: readonly any[]
    solutions?: readonly any[]
  }
}

const RelatedResources = (props: RelatedResourcesProps) => {
  const [showCTA, setShowCTA] = useState(true)

  const { content } = props
  const { rows = 1 } = content
  // related resources are pulled in via a filter, grabbing any resource with a matching role
  const normalResources = content?.related_resources || content?.solutions || []
  // editors can also add in promoted resources on a per-page basis; those get inserted at
  // the start of the array
  const promotedResources = [...(content?.promoted_resources ?? [])].reverse()
  const allResources = [...promotedResources, ...normalResources]
  // the cms also sets how many rows of resources to show (defaults to one; there are
  // three cards per row, so if the array is too long, truncate it to fit)
  const resourcesToDisplay =
    allResources.length > rows * 3
      ? allResources.slice(0, rows * 3)
      : allResources

  useEffect(() => {
    // if there is a button
    if (typeof window !== 'undefined' && content?.cta_button?.[0]) {
      if (
        window.location.pathname.replace(/\//g, '') ===
        content?.cta_button?.[0]?.link?.[0]?.internal_overview_page?.[0]?.url.replace(
          /\//g,
          '',
        )
      ) {
        setShowCTA(false)
      }
    }
  }, [])

  return (
    <RelatedResourcesSection>
      <Wrapper>
        <Typography
          className="headine"
          color="primary"
          component="h2"
          variant="h3"
        >
          {content?.headline}
        </Typography>
        <Grid
          className="cardContainer"
          container
          justifyContent="space-between"
        >
          {resourcesToDisplay?.map((resource: any) => {
            const resourceTypeTitles = resource.resource?.flatMap((res: any) =>
              res.resource_type?.map((type: any) => type.title),
            )

            return (
              <FeatureCardVertical
                body={
                  resource.resource?.[0]?.short_description ||
                  resource.product?.[0]?.short_description ||
                  resource.program?.[0]?.short_description ||
                  resource.client_story?.[0]?.short_description ||
                  resource?.metadata?.description ||
                  resource.product_category?.[0]?.short_description
                }
                cardType={resourceTypeTitles?.join(', ') || ''}
                featureImage={
                  resource.resource?.[0]?.feature_image ||
                  resource.product?.[0]?.feature_image ||
                  resource.program?.[0]?.feature_image ||
                  resource.page_header?.[0]?.feature_image ||
                  resource.client_story?.[0]?.feature_image ||
                  resource.product_category?.[0]?.feature_image
                }
                headline={
                  resource.resource?.[0]?.headline ||
                  resource.product?.[0]?.headline ||
                  resource.program?.[0]?.headline ||
                  resource.client_story?.[0]?.headline ||
                  resource?.metadata?.page_title ||
                  resource.product_category?.[0]?.headline
                }
                key={resource.id || resource.resource?.[0]?.id}
                linkTarget={resource.url || resource.pageUrl}
              />
            )
          })}
        </Grid>
        {content?.cta_button?.[0] && showCTA ? (
          <Grid className="ctaContainer" container justifyContent="center">
            <LinkCTA
              arrow
              link={content.cta_button[0].link?.[0]}
              variant="outline"
            >
              {content.cta_button[0].text}
            </LinkCTA>
          </Grid>
        ) : null}
      </Wrapper>
    </RelatedResourcesSection>
  )
}

export default RelatedResources
