import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'
import Wrapper from '../UI/Wrapper'

export const HeroWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  min-height: 512px;
  max-height: 700px;
  position: relative;

  & .gatsby-image-wrapper {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    z-index: -1;
    width: 100%;
    height: 100%;
    max-width: 1920px;
  }

  &.video.largeVariant {
    max-height: 512px;
    min-height: 512px;
    height: 512px;
  }

  > video {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    z-index: -1;
    width: 100%;
    height: 100%;
    max-width: 1800px;
    object-fit: cover;
    max-height: 512px;
  }

  & .gatsby-image-wrapper [data-main-image] {
    // removes the fade-in animation
    opacity: 1 !important;
  }

  &.largeVariant {
    min-height: 696px;
  }

  &.noImage {
    min-height: unset;
    padding: 48px 0;
  }

  @media (max-width: 580px) {
    min-height: 610px;

    &.largeVariant {
      min-height: 640px;
    }

    &.noImage {
      min-height: unset;
      padding: 0 0 40px;
    }

    & .gatsby-image-wrapper img {
      object-position: 80%;
    }
  }
`

export const ContentWrapper = styled(Wrapper)`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  color: white;
  display: flex;
  z-index: 3;

  .headline {
    margin-bottom: 32px;
    max-width: 14ch;

    &.noImage {
      max-width: unset;
    }
    &.headlineLight {
      color: inherit;
    }
    &.headlineDark {
      color: ${rspTheme.palette.primary.main};
    }
    &.headlineLarge {
      font-size: 6.2rem;

      @media (max-width: 580px) {
        font-size: 4.6rem;
        line-height: 1.25;
      }
    }

    @media (max-width: 840px) {
      font-size: 2.8rem;
      letter-spacing: -0.2px;
      line-height: 1.35;
      margin-bottom: 16px;
      max-width: 28ch;

      &.noImage {
        max-width: unset;
      }
    }
  }

  .subhead {
    max-width: 40ch;

    &.noImage {
      max-width: unset;
    }

    &.subheadLarge {
      font-size: 2.4rem;

      @media (max-width: 580px) {
        font-size: 2.1rem;
      }
    }
  }

  .subheadLight {
    color: inherit;
  }
  .subheadDark {
    color: ${rspTheme.palette.text.primary};
  }

  @media (max-width: 580px) {
    padding: 80px 0 40px;
    margin-top: auto;

    .subhead {
      max-width: unset;
    }
  }
`

export const GradientOverlay = styled.div`
  &.overlayLight {
    background-image: linear-gradient(to top, rgba(15, 3, 74, 1), transparent);
  }
  &.overlayDark {
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      transparent
    );
  }

  position: absolute;
  display: none;
  top: 20%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 580px) {
    display: block;
  }
`

interface HalfWidthProps {
  align: string
}

export const HalfWidth = styled.div<HalfWidthProps>`
  width: calc(50% - 2rem);
  ${(props) =>
    props.align === 'Right' ? 'margin-left: auto;' : 'margin-right: auto;'}

  @media (max-width: 580px) {
    width: 100%;
  }
`

export const AccessibilityGradient = styled.div`
  position: absolute;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(15, 3, 74, 0) 50%
  );
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  @media (max-width: 580px) {
    display: none;
  }
`
export const AccessibilityOverlay = styled.div`
  position: absolute;
  background: ${rspTheme.palette.primary.main}59;
  top: 0%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`
