import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ImpactSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  & .gatsby-image-wrapper {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
`

interface ContentContainerProps {
  theme: 'Dark' | 'Light'
}

export const ContentContainer = styled.div<ContentContainerProps>`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  align-self: center;
  padding: 40px 0 48px;
  color: ${({ theme }) =>
    theme === 'Dark' ? '#ffffff' : rspTheme.palette.primary.main};
  text-align: center;

  .subhead {
    margin-block-end: 48px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin-block-end: 24px;
      font-size: 2.8rem;
    }
  }

  .statGrid {
    list-style: none;
    padding: 0;
    justify-content: space-between;
    @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
      justify-content: center;
    }
  }

  .statCaption {
    display: block;
    max-width: 20ch;
    margin: 0 auto;
  }
`

export const StatItem = styled.li`
  min-width: 220px;
  margin: 0 auto 24px;
`

export const StatValue = styled.strong`
  display: block;
  font-size: 6.4rem;
  font-weight: 600;
  letter-spacing: -1.5px;
  line-height: 1;
  margin: 40px auto 48px;
  background-image: linear-gradient(${rspTheme.gradient});
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    font-size: 7.2rem;
    margin: 24px auto 16px;
  }
`
