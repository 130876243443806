interface DownloadResourceData {
  headline: string
  feature_image: string
  linkTarget: string
  linkText: string
  side_cta_short_description: string
}

function createDownloadObject(
  data: any,
  dataSource: 'props' | 'data',
): DownloadResourceData {
  const dataSourceObj = dataSource === 'props' ? data?.data : data

  return {
    headline:
      dataSourceObj?.downloadResourceCard?.nodes?.[0]?.resource?.[0]
        ?.headline || '',
    feature_image:
      dataSourceObj?.downloadResourceCard?.nodes?.[0]?.resource?.[0]
        ?.feature_image || '',
    linkTarget: dataSourceObj?.downloadResourceCard?.nodes?.[0]?.url || '',
    linkText:
      dataSourceObj?.allContentstackResourcesWebsite?.nodes?.[0]
        ?.download_card_cta_text || '',
    side_cta_short_description:
      dataSourceObj?.downloadResourceCard?.nodes?.[0]?.resource?.[0]
        ?.side_cta_short_description || '',
  }
}

export default createDownloadObject
