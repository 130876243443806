import styled from 'styled-components'

import linkTriangle from '../../../images/linkTriangle.svg'
import { rspTheme } from '../../../styles/rspTheme'

export const CardWrapper = styled.span`
  a {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-decoration: none;
    transition: box-shadow 0.3s ease-in-out;
    height: 100%;
    min-height: 320px;
    max-width: 360px;

    &:hover,
    &:focus {
      outline: 0px;
      box-shadow: 0px 0px 32px 10px rgba(0, 0, 0, 0.16);
    }
  }
`

export const ContentBox = styled.div`
  padding: 16px 20px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;

  .featureText {
    color: ${rspTheme.palette.primary.main};
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.35;
    margin-top: 12px;

    & + .bodyWrapper {
      margin-top: 8px;
    }

    @media (max-width: 980px) {
      font-size: 1.6rem;
    }

    span {
      position: relative;
    }

    span::after {
      content: '';
      position: absolute;
      display: block;
      width: 5px;
      height: 9px;
      right: -13px;
      bottom: 6px;
      background-image: url(${linkTriangle});
    }
  }

  .bodyWrapper {
    overflow: hidden;
  }

  .cardBody {
    color: ${rspTheme.palette.text.primary};
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.35;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    @media (max-width: 980px) {
      font-size: 1.4rem;
    }
  }

  .cardType {
    color: ${rspTheme.palette.text.disabled};
    font-size: ${rspTheme.typography.caption.fontSize};
    font-weight: ${rspTheme.typography.caption.fontWeight};
    letter-spacing: ${rspTheme.typography.caption.letterSpacing};
    line-height: ${rspTheme.typography.caption.lineHeight};
    margin-top: auto;
  }
`

export const ImageBox = styled.div`
  height: 40%;
  background-color: ${rspTheme.palette.primary.background};
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;

    img {
      min-width: 100%;
      object-position: top center;
    }
  }
`
