import { graphql, HeadProps, PageProps } from 'gatsby'
import React, { useEffect } from 'react'

import Hero from '../components/Hero'
import ImpactModule from '../components/Impact'
import KeyAreas from '../components/KeyAreas'
import Layout from '../components/Layout'
import PageBody from '../components/PageBody'
import RelatedResources from '../components/RelatedResources'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import StepForm from '../components/StepForm'
import TrainStopSection from '../components/TrainstopSection'
import { useSplitTracking } from '../hooks/useSplitTracking'
import createDownloadObject from '../utils/createDownloadObject'
import { useTrackSolutionPage } from '../utils/dataLayer'

const ProductSpecificPage = ({
  data,
  pageContext,
}: PageProps<
  Queries.productSpecificPageQuery,
  { filterType: string; slug: string }
>) => {
  const pageData = data?.contentstackPageProduct
  const parentPage = data?.parentCategoryPage?.nodes?.[0]
  const grandparentPage = data?.grandparentCategoryPage?.nodes?.[0]

  const breadcrumbLinks = [{ text: pageData?.title || '', target: '' }]

  if (parentPage?.title && parentPage?.url) {
    breadcrumbLinks.unshift({
      text: parentPage?.title,
      target: parentPage?.url,
    })
  }
  if (grandparentPage?.title && grandparentPage?.url) {
    breadcrumbLinks.unshift({
      text: grandparentPage?.title,
      target: grandparentPage?.url,
    })
  }

  const resourceDownloadUrl =
    data?.downloadResourceUrl?.nodes?.[0]?.pageUrl || '/'

  let resourcesByFilter
  switch (pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...(data?.resourceCardsByResourceTypeFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [
        ...(data?.resourceCardsByIndustryFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...(data?.resourceCardsByRoleFilter?.nodes || [])]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards = data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  const downloadResourceObject = createDownloadObject(data, 'data')

  useTrackSolutionPage(pageContext.slug, data)

  const track = useSplitTracking()

  useEffect(() => {
    const salesAnalyticsPage =
      window.location.pathname.includes('sales-analytics') ||
      window.location.pathname.includes(
        'sales-consulting-services/assessments/',
      )

    if (salesAnalyticsPage) {
      track('sales_analytics__pageview', undefined, {
        url: window.location.href,
      })
    }
    track('product_specific__pageview', undefined, {
      url: window.location.href,
    })
  }, [])

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />

      <PageBody
        content={pageData?.page_body?.[0]}
        downloadResource={downloadResourceObject}
        downloadUrl={resourceDownloadUrl}
      />

      {pageData?.optional_sections?.map((section: any) => (
        <React.Fragment key={section.id}>
          {section.internal?.type == 'Contentstack_section_key_areas' && (
            <KeyAreas content={section} />
          )}
          {section.internal?.type === 'Contentstack_section_impact_stats' && (
            <ImpactModule content={section} />
          )}
          {section?.internal?.type === 'Contentstack_section_train_stop' && (
            <TrainStopSection
              ctaText={section.cta_text}
              headline={section.headline}
              isHeroTrainStop={section.is_hero_train_stop}
              slides={section.slides}
            />
          )}
        </React.Fragment>
      ))}
      {pageData?.contact_us_form?.[0]?.variant === 'Step' && (
        <StepForm formData={pageData?.contact_us_form?.[0]} />
      )}
      <RelatedResources
        content={{
          related_resources: resourcesByFilter || [],
          ...pageData?.related_resources?.[0],
        }}
      />
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.productSpecificPageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageProduct?.schema_markup} />
    <SchemaMarkupBreadcrumb
      grandParent={data?.grandparentCategoryPage?.nodes?.[0]}
      pageTitle={data?.contentstackPageProduct?.title}
      parent={{
        title: data?.parentCategoryPage?.nodes?.[0]?.title,
        pageUrl: data?.parentCategoryPage?.nodes?.[0]?.url,
      }}
    />
  </>
)

export default ProductSpecificPage

export const query = graphql`
  query productSpecificPage(
    $pageId: String
    $filterId: String
    $downloadResourceId: String
    $locale: String
    $downloadId: String
    $parentCategoryId: String
    $grandparentCategoryId: String
  ) {
    contentstackPageProduct(id: { eq: $pageId }) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      page_body {
        ...PageBodySection
      }
      optional_sections {
        ... on Contentstack_section_impact_stats {
          ...ImpactStatsSection
        }
        ... on Contentstack_section_key_areas {
          ...KeyAreasSection
        }
        ... on Contentstack_section_train_stop {
          ...TrainStopSection
        }
      }
      contact_us_form {
        ...LeadGenForm
      }
      related_resources {
        ...RelatedResourcesSection
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { is_featured: { eq: "Yes" } } }
      }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    downloadResourceUrl: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { id: { eq: $downloadResourceId } } }
      }
    ) {
      nodes {
        pageUrl: url
        resource {
          id
        }
      }
    }

    downloadResourceCard: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { is_gated: { eq: "Yes" }, id: { eq: $downloadId } }
        }
      }
    ) {
      nodes {
        title
        url
        resource {
          headline
          side_cta_short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 60 })
            }
          }
        }
      }
    }

    parentCategoryPage: allContentstackPageProductOverview(
      filter: {
        publish_details: { locale: { eq: $locale } }
        product_category: { elemMatch: { id: { eq: $parentCategoryId } } }
      }
    ) {
      nodes {
        title
        url
      }
    }

    grandparentCategoryPage: allContentstackPageProductOverview(
      filter: {
        publish_details: { locale: { eq: $locale } }
        product_category: { elemMatch: { id: { eq: $grandparentCategoryId } } }
      }
    ) {
      nodes {
        title
        url
      }
    }

    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        download_card_cta_text
      }
    }
  }
`
