import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import { buildImageDataLike } from '../../../utils/helpers'
import { IAsset } from '../../../utils/types'
import Link from '../../Link'
import { CardWrapper, ContentBox, ImageBox } from './styles'

interface FeatureCardVerticalProps {
  headline: string
  body?: string
  cardType: string
  featureImage: IAsset | null | undefined
  linkTarget: string
}

const FeatureCardVertical = ({
  headline,
  cardType,
  featureImage,
  linkTarget = '/',
  body,
}: FeatureCardVerticalProps) => {
  const thumbnailImage = getImage(
    buildImageDataLike(headline, featureImage?.imgixImage),
  )
  const altText = featureImage?.description || ''

  const isPressRelease = cardType === 'Press Release'

  if (linkTarget?.startsWith('http')) {
    return (
      <CardWrapper>
        <a href={linkTarget} rel="noopener noreferrer" target="_blank">
          <ImageBox>
            {thumbnailImage ? (
              <GatsbyImage alt={altText} image={thumbnailImage} />
            ) : null}
            {!thumbnailImage && isPressRelease ? (
              <StaticImage
                alt="Press Release"
                src="../../../images/richardson-news-events.jpg"
              />
            ) : null}
          </ImageBox>
          <ContentBox>
            <p className="featureText">
              <span>{headline}</span>
            </p>
            {body ? (
              <div className="bodyWrapper">
                <p className="cardBody">{body}</p>
              </div>
            ) : null}
            {cardType ? <p className="cardType">{cardType}</p> : null}
          </ContentBox>
        </a>
      </CardWrapper>
    )
  }

  return (
    <CardWrapper>
      <Link directUrl={linkTarget}>
        <>
          <ImageBox>
            {thumbnailImage ? (
              <GatsbyImage alt={altText} image={thumbnailImage} />
            ) : null}
            {!thumbnailImage && isPressRelease ? (
              <StaticImage
                alt="Press Release"
                src="../../../images/richardson-news-events.jpg"
              />
            ) : null}
          </ImageBox>
          <ContentBox>
            <p className="featureText">
              <span>{headline}</span>
            </p>
            {body ? (
              <div className="bodyWrapper">
                <p className="cardBody">{body}</p>
              </div>
            ) : null}
            {cardType ? <p className="cardType">{cardType}</p> : null}
          </ContentBox>
        </>
      </Link>
    </CardWrapper>
  )
}

export default FeatureCardVertical
